<template>
  <div class="tab">
    <div v-if="!this.isLoading">
      <div class="dashboard-menu">
        <div
          class="dashboard-menu-item"
          @click="currentMenu = 1"
          :class="[currentMenu === 1 ? 'active' : '']"
        >
          Panel główny
        </div>
      </div>
      <div v-if="currentMenu === 1">
        <b-row class="main-settings" align-v="stretch">
          <b-col>
            <info-bar
              title="Wybrany tydzień"
              :content="`${returnWeekText}`"
              icon="calendar"
              variant="secondary"
            ></info-bar>
          </b-col>
          <b-col
            ><info-bar
              title="Ilość dostępnych zmian"
              variant="secondary"
              icon="puzzle"
              :content="leftShifts"
            ></info-bar
          ></b-col>
        </b-row>
        <b-row class="align-items-center info-section">
          <b-col
            ><info-bar
              title="Wypełnienie grafiku"
              variant="warning"
              icon="square-half"
              :content="`${
                getAverageShifts.perc > 0
                  ? getAverageShifts.perc + '%'
                  : 'Wygeneruj grafik'
              }`"
          /></b-col>
          <b-col
            ><info-bar
              title="Wypełnienie zmian"
              variant="secondary"
              icon="layers-half"
              :content="`${returnCapacity.takenShifts}/${returnCapacity.availableShifts} `"
          /></b-col>
          <b-col
            ><info-bar
              title="Przesłane grafiki"
              variant="secondary"
              icon="people"
              :content="
                returnDetails.amount > 0
                  ? returnDetails.amount
                  : 'Brak przesłanych grafików'
              "
          /></b-col>
        </b-row>
        <b-row class="align-items-center info-section">
          <b-col
            ><info-bar
              title="Potrzebne osoby do grafiku"
              variant="warning"
              icon="exclamation-circle"
              :content="getAverageShifts.avgLeft"
          /></b-col>
          <b-col
            ><info-bar
              title="Średnia zmian / osoba"
              variant="secondary"
              icon="bar-chart-fill"
              :content="`${getAverageShifts.avgPerPerson} `"
          /></b-col>
          <b-col
            ><info-bar
              title="Aktywni kierowcy"
              icon="pencil"
              :content="`${returnDrivers.length}`"
          /></b-col>
        </b-row>
        <b-row class="shift-checker" v-if="getTopDays">
          <b-col>
            <info-bar
              title="Ostatnio przesłane"
              :content="returnDetails.schedules.slice(-3)"
              icon="calendar"
              variant="secondary"
              mode="lastPersons"
            ></info-bar>
          </b-col>
        </b-row>
        <b-row class="shift-checker" v-if="getTopDays">
          <b-col>
            <info-bar
              title="Braki dyspozycji"
              :content="returnMissing"
              icon="calendar"
              variant="secondary"
              mode="missing"
            ></info-bar>
          </b-col>
        </b-row>
        <!-- <b-row class="shift-checker" v-if="returnDoorEvents">
          <b-col>
            <info-bar
              title="Najbliższe wyłączenia"
              :content="returnDoorEvents"
              icon="calendar"
              variant="warning"
              mode="doorHandler"
            ></info-bar>
          </b-col>
        </b-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import infoBar from "../components/bars/InfoBar.vue";
export default {
  name: "Dashboard-panel",
  components: {
    infoBar,
  },
  data() {
    return {
      isLoading: false,
      currentMenu: 1,
      isResultsLoading: false,
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      await this.getSettings();
      await this.$store
        .dispatch("fetchDetails", {
          week: this.returnSettings.adminWeek,
          year: this.returnSettings.adminYear,
        })
        .catch((error) => console.log(error));
      await this.$store.dispatch("getDrivers");
      await this.$store.dispatch("fetchGeneratedWeek", {
        week: this.$store.state.settings.adminWeek,
        year: this.returnSettings.adminYear,
      });
      // await this.$store.dispatch("getDoorEvents");
      this.isLoading = false;
    },
    async getSettings() {
      this.isLoading = true;
      await this.$store.dispatch("fetchASettings");
    },
    async changeTabToResult() {
      this.isResultsLoading = true;
      await this.$store.dispatch("fetchDashboardDailyResult");
      this.currentMenu = 2;

      this.isResultsLoading = false;
    },
    async changeTabToLastWeek() {
      this.isResultsLoading = true;
      await this.$store.dispatch("fetchDashboardWeeklyResult");
      this.currentMenu = 3;

      this.isResultsLoading = false;
    },
  },
  computed: {
    getTopDays() {
      return !this.isLoading && this.$store.getters["returnMaxDays"];
    },
    returnDoorEvents(){
      return !this.isLoading && this.$store.getters["returnDoorEvents"]
    },
    returnDrivers() {
      const driversList =
        !this.isLoading && this.$store.getters["getDriverList"];
      return driversList.filter((el) => el.active);
    },
    returnSettings() {
      return this.$store.getters["getSettings"];
    },
    returnDashboardShifts() {
      const response = this.$store.getters["getDashboardResult"];
      const dayShifts = response.filter(
        (item) => item && item.attributes?.shift === "day"
      );
      const nightShifts = response.filter(
        (item) => item && item.attributes?.shift === "night"
      );

      dayShifts.sort((a, b) => b.totalEarned - a.totalEarned);
      nightShifts.sort((a, b) => b.totalEarned - a.totalEarned);

      return !this.isResultsLoading && { dayShifts, nightShifts };
    },
    returnWeeklyDashboardShifts() {

      return !this.isResultsLoading && this.$store.getters["getDashboardWeeklyResult"];
    },

    returnDetails() {
      return !this.isLoading && this.$store.getters["getDetails"];
    },
    returnMissing() {
      const schedules = this.returnDetails.schedules;
      const listOfSchedules = [];
      for (let i = 0; i < schedules.length; i++) {
        let id = schedules[i].id;
        listOfSchedules.push({ ...schedules[i].attributes, id });
      }
      const missingDrivers = [];
      for (let i = 0; i < this.returnDrivers.length; i++) {
        let find = listOfSchedules.find(
          (el) => this.returnDrivers[i].phoneNumber === el.phone
        );
        if (!find) {
          missingDrivers.push(this.returnDrivers[i]);
        }
      }
      return missingDrivers;
    },
    getClosestDoorEvents(){

    },
    returnWeekText() {
      const adminWeek = this.$store.state.settings.adminWeek;
      let year = this.$store.state.settings.adminYear;

      return `${this.$dayjs().week(adminWeek).year(year).weekday(0).$D}.${
        this.$dayjs().week(adminWeek).year(year).weekday(0).$M + 1
      }.${this.$dayjs().week(adminWeek).year(year).weekday(0).year()} - ${
        this.$dayjs().week(adminWeek).year(year).weekday(6).$D
      }.${
        this.$dayjs().week(adminWeek).year(year).weekday(6).$M + 1
      }.${this.$dayjs().week(adminWeek).year(year).weekday(6).year()}`;
    },
    getAverageShifts() {
      const shiftNum = this.returnDetails.shiftsAmount;
      const people = this.returnDetails.amount;
      const avgPeople = shiftNum / people;
      const avgLeft = this.leftShifts / avgPeople;
      const perc =
        (100 * this.returnCapacity.takenShifts) /
        this.returnCapacity.availableShifts;
      return (
        !this.isLoading && {
          avgPerPerson: avgPeople ? avgPeople.toFixed(1) : "",
          avgLeft: avgLeft ? avgLeft.toFixed(1) : "",
          perc: perc.toFixed(0),
        }
      );
    },
    generatedWeek() {
      const week = this.$store.state.generatedWeeks[0];
      return week ? { day: week.days, night: week.nights } : false;
    },
    returnCapacity() {
      const nights = this.generatedWeek.night;
      const days = this.generatedWeek.day;
      let availableShifts = 0;
      let takenShifts = 0;
      if (days && nights) {
        for (let i = 0; i < days.length; i++) {
          const day =  days[i].day;
          let dayCars = days[i].cars;
          let nightCars = nights[i].cars;
          for (let a = 0; a < dayCars.length; a++) {
            const reg = dayCars[a].reg;
            // check if not on night shift

            const driverFullName = dayCars[a].drivers[0]?.fullName ?? null;
            const lookOnNight = nightCars.find(el => el.reg === reg);
            if(lookOnNight){
              if(lookOnNight.drivers.length && lookOnNight.drivers[0].fullName === driverFullName){
                availableShifts ++;
                continue;
              } else {
                takenShifts += dayCars[a].drivers.length;

              }
            }
            if (!dayCars[a].grounded ) {
              availableShifts += 1;
            }
          }
          for (let a = 0; a < nightCars.length; a++) {
            takenShifts += nightCars[a].drivers.length;
            // check if not on day shift
            
            if (!nightCars[a].grounded) {
              availableShifts += 1;
            }
          }
        }
      }

      return !this.isLoading && { availableShifts, takenShifts };
    },
    getShiftNumber() {
      const limit = this.returnSettings.defaultLimit;
      const maxShifts = 7 * limit * 2;
      return !this.isLoading && maxShifts;
    },
    leftShifts() {
      return (
        !this.isLoading &&
        this.returnCapacity.availableShifts - this.returnCapacity.takenShifts
      );
    },
    returnCurrentUser(){
      return !this.isLoading && this.$store.state.user
    }
  },
};
</script>

<style scoped>
.info-section,
.main-settings,
.shift-checker {
  gap: 15px;
}
.dashboard-menu {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.dashboard-menu-item {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid #f78812;
}
.dashboard-menu-item:hover {
  background-color: #f78812;
  color: #fff;
  transition: 0.2s;
}
.active {
  background-color: #f78812;
}

.tab {
  width: 100%;
}

.result-box {
  border-radius: 16px;
  margin: 16px 0;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  padding: 32px;
}

.total-earned {
  font-size: 1.4em;
  font-weight: 600;
  text-align: right;
}
.total-earned .currency {
  font-size: 16px;
}
.apps {
  margin: 16px 0;
}
.app-single {
  margin: 8px 0;
}

.uber {
  border-bottom: 1px solid black;
}
.bolt {
  border-bottom: 1px solid rgb(65, 233, 32);
}
.freenow {
  border-bottom: 1px solid rgb(153, 51, 51);
}
.app-title {
  font-weight: 300;
}
.cash {
  text-align: right;
}
.weekly-box{
    border-radius: 16px;
  margin: 16px 0;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  box-shadow: 8px 8px 24px 0px rgba(211, 212, 221, 1);
  padding: 32px;
}
</style>
