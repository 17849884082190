<template>
  <b-modal
    centered
    id="door-handler"
    ok-title="Zamknij"
    size="xl"
    ok-only
    title="Zaplanuj zamknięcia / otwarcia"
  >
    <table class="closing-planning" v-if="returnClosingEvents">
      <tr>
        <th>Numer rejestracyjny</th>
        <th v-for="(day, index) in days" :key="day.id">
          {{ day.day }}.{{ day.month }}<br />
          {{ dayNames[index] }}
        </th>
      </tr>
      <tr v-for="car in cars">
        <td>{{ car.reg }}</td>
        <td v-for="day in createDays">
          <div class="d-flex flex-column">
            <b-badge
              :variant="[
                item.attributes.type === 'Otwórz' ? 'success' : 'danger',
              ]"
              class="p-2 my-2 hour-badge"
              v-for="item in returnEvents(car.reg, day)"
              ><span class="hour">{{
                item.attributes.time?.split(".")[0]
              }}</span>
              <span class="trash"
                ><b-icon @click="removeDoorEvent(item.id)" icon="trash"
              /></span>
            </b-badge>
          </div>
        </td>
      </tr>
      <tr>
        <td>Sterowanie</td>
        <td v-for="day in createDays">
          <b-icon
            icon="plus-lg"
            @click="toggleAddingMode(day)"
            class="rounded-circle bg-primary p-2 pointer"
            variant="light"
            font-scale="1.4"
          ></b-icon>
        </td>
      </tr>
    </table>
    <div class="steering mt-3" v-if="addingMode">
      <h4>Typ zdarzenia</h4>
      <div class="d-flex flex-wrap">
        <div
          class="car border border-success rounded p-4 m-2 pointer"
          :class="[selectedMode === 'Otwórz' ? 'bg-success' : '']"
          @click="selectedMode = 'Otwórz'"
        >
          Otwórz
        </div>
        <div
          class="car border border-danger rounded p-4 m-2 pointer"
          :class="[selectedMode === 'Zamknij' ? 'bg-danger' : '']"
          @click="selectedMode = 'Zamknij'"
        >
          Zamknij
        </div>
      </div>
      <h4>Wybierz auto</h4>
      <div class="d-flex g-2 flex-wrap">
        <div
          class="car border border-primary rounded p-4 m-2 pointer"
          :class="[
            selectedCars.find((el) => el.reg === car.reg) ? 'selected' : '',
          ]"
          v-for="car in cars"
          @click="toggleCar(car)"
        >
          {{ car.reg }}
        </div>
      </div>
      <h4>Wybierz dzień</h4>
      <div class="d-flex g-2 flex-wrap">
        <div
          class="car border border-primary rounded p-4 m-2 pointer text-center"
          :class="[selectedDays.find((el) => el === day) ? 'selected' : '']"
          v-for="(day, index) in createDays"
          @click="toggleDay(day)"
        >
          {{ day }}<br />
          {{ dayNames[index] }}
        </div>
      </div>
      <h4>Wybierz godzinę</h4>
      <div class="text-center show-num">
        Wybrana godzina: {{ selectedHour }}
      </div>

      <b-form-input
        type="range"
        min="0"
        max="23"
        v-model="selectedHour"
      ></b-form-input>
      <h4>Wybierz minutę</h4>
      <div class="text-center show-num">
        Wybrana minuta: {{ selectedMinute }}
      </div>

      <b-form-input
        type="range"
        min="0"
        max="59"
        v-model="selectedMinute"
      ></b-form-input>
      <div class="text-center">
        <b-button @click="clearSettings">Wyczyść ustawienia</b-button
        ><b-button
          variant="primary"
          class="ml-2"
          @click="handleSubmit"
          :disabled="isSending"
          >Zapisz</b-button
        >
      </div>
    </div>
  </b-modal>
</template>


<script>
export default {
  props: ["cars", "days", "createDays"],
  data() {
    return {
      form: {
        date: null,
        time: null,
        type: null,
      },
      dayNames: [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ],
      addingMode: false,
      selectedCars: [],
      selectedDays: [],
      selectedHour: 8,
      selectedMinute: 0,
      selectedMode: "",
      isSending: false,
    };
  },
  methods: {
    async handleSubmit() {
      this.isSending = true;
      const hour = this.selectedHour.toString().padStart(2, "0");
      const minute = this.selectedMinute.toString().padStart(2, "0");
      const selectedMode = this.selectedMode;

      for (let i = 0; i < this.selectedCars.length; i++) {
        const reg = this.selectedCars[i].reg;
        const carId = this.selectedCars[i].id;

        for (let b = 0; b < this.selectedDays.length; b++) {
          const splittedDay = this.selectedDays[b].split(".");
          const formmattedDay = `${splittedDay[2]}-${splittedDay[1].padStart(
            2,
            "0"
          )}-${splittedDay[0].padStart(2, "0")}`;
          const formmattedTime = `${hour}:${minute}:00`;
          console.log("adding event");
          await this.$store.dispatch("addDoorHandler", {
            date: formmattedDay,
            time: formmattedTime,
            type: selectedMode,
            carId: carId.toString(),
            reg,
          });
        }
      }

      await this.$store.dispatch("getDoorEvents", {
        start: this.startDayFormatted,
        end: this.endDayFormatted,
      });

      this.isSending = false;
    },
    returnEvents(carReg, day) {
      const daySplit = day.split(".");
      const dayForrmatted = `${daySplit[2]}-${daySplit[1].padStart(
        2,
        "0"
      )}-${daySplit[0].padStart(2, "0")}`;
      const events = this.returnClosingEvents.filter(
        (el) =>
          el.attributes.date === dayForrmatted && el.attributes.reg === carReg
      );

      events.sort((a, b) => a.attributes.time.localeCompare(b.attributes.time));
      return events;
    },
    toggleAddingMode(day) {
      this.addingMode = true;
      this.selectedDays.push(day);
    },
    toggleCar(car) {
      if (this.selectedCars.findIndex((el) => el.reg === car.reg) >= 0) {
        const index = this.selectedCars.findIndex((el) => el.reg === car.reg);
        this.selectedCars.splice(index, 1);
      } else {
        // Add the car to the array
        this.selectedCars.push(car);
      }
    },
    toggleDay(day) {
      if (this.selectedDays.findIndex((el) => el === day) >= 0) {
        const index = this.selectedDays.findIndex((el) => el === day);
        this.selectedDays.splice(index, 1);
      } else {
        // Add the car to the array
        this.selectedDays.push(day);
      }
    },
    async removeDoorEvent(eventId) {
      await this.$store.dispatch("removeDoorHandler", eventId);
      await this.$store.dispatch("getDoorEvents", {
        start: this.startDayFormatted,
        end: this.endDayFormatted,
      });
    },
    setHour(hour) {
      this.selectedHour = hour - 1;
    },
    clearSettings() {
      (this.selectedCars = []), (this.selectedDays = []);
      this.selectedHour = 5;
      this.selectedMinute = 0;
      this.selectedMode = "";
      this.form.date = null;
      this.form.time = null;
      this.form.type = null;
    },
  },
  computed: {
    returnClosingEvents() {
      return this.$store.getters["returnDoorEvents"];
    },
    startDayFormatted() {
      const firstDay = this.createDays[0].split(".");
      const formattedStart = `${firstDay[2]}-${firstDay[1].padStart(
        2,
        "0"
      )}-${firstDay[0].padStart(2, "0")}`;
      return formattedStart;
    },
    endDayFormatted() {
      const lastDay = this.createDays[6].split(".");
      const formattedEnd = `${lastDay[2]}-${lastDay[1].padStart(
        2,
        "0"
      )}-${lastDay[0].padStart(2, "0")}`;
      return formattedEnd;
    },
  },
};
</script>


<style scoped>
.closing-planning {
  width: 100%;
  border-collapse: no-collapse;
  border: 1px solid #4e73df;
}
.closing-planning th,
td {
  text-align: center;
  height: 40px;
  border: 1px solid #4e73df;
  font-size: 1.2em;
}
.closing-planning td {
  padding: 4px;
}

.hour-badge:hover .hour {
  display: none;
}
.hour-badge .trash {
  display: none;
}
.selected {
  background: #4e73df;
  color: #fff;
}
.hour-badge:hover .trash {
  display: block;
  cursor: pointer;
}
.show-num {
  color: #4e73df;
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 1.3em;
}
</style>